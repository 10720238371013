"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const filterPostsByCategory_1 = require("@/util/filterPostsByCategory");
function PostHeader(posts, selectedCategory) {
    const filteredPosts = (0, filterPostsByCategory_1.filterPostsByCategory)(posts, selectedCategory);
    const content = `
  <div class="flex flex-col justify-center text-center">
    <div class="text-title-bold">${selectedCategory}</div>
    <div class="p-3">${filteredPosts.length} posts</div>
  </div>
  `;
    return content;
}
exports.default = PostHeader;
