"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Footer() {
    return `
<footer class="w-full relative left-0 bottom-0 h-16 bg-black-100 flex items-center justify-center">
  <p>
    <a href="https://github.com/y-baaam" class="visited:text-white-300">@ young beom</a>
  </p>
</footer>
`;
}
exports.default = Footer;
