"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupCategoryListeners = void 0;
/**
 * DOM의 클릭 이벤트에 대한 이벤트 리스너를 설정합니다.
 * 지정된 카테고리에 해당하는 DOM 요소가 클릭되면 상태를 업데이트하고
 * 해당 카테고리에 맞게 화면을 다시 렌더링합니다.
 *
 * @param categoryState 현재 선택된 카테고리의 상태를 관리하는 객체
 * @param render 카테고리가 변경되었을 때 호출되어 화면을 다시 렌더링하는 함수
 */
function setupCategoryListeners({ categoryState, render, }) {
    document.addEventListener("click", (e) => {
        let target = e.target;
        while (target && !target.hasAttribute("data-category")) {
            target = target.parentElement;
        }
        if (target && target.hasAttribute("data-category")) {
            const category = target.getAttribute("data-category");
            if (category) {
                categoryState.setValue(category);
                render();
            }
        }
    });
}
exports.setupCategoryListeners = setupCategoryListeners;
