"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterPostsByCategory = void 0;
/**
 * 카테고리에 따라 포스트를 필터링합니다.
 * @param {Post[]} posts  카테고리별로 구분할 포스트 목록
 * @param {string | null} category  필터링할 카테고리
 */
function filterPostsByCategory(posts, category) {
    return category === "All" || !category
        ? posts
        : posts.filter((post) => post.category === category);
}
exports.filterPostsByCategory = filterPostsByCategory;
