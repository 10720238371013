"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class App {
    constructor(rootElement) {
        if (!rootElement)
            return;
        // 초기화 로직
    }
}
exports.default = App;
