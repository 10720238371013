"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllPosts = exports.getFeaturedPublicPosts = void 0;
async function getFeaturedPublicPosts() {
    const posts = await getAllPosts();
    return posts.filter((post) => post.public);
}
exports.getFeaturedPublicPosts = getFeaturedPublicPosts;
async function getAllPosts() {
    const url = "/content/posts.json";
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const posts = await response.json();
        return posts.sort((a, b) => (a.date > b.date ? -1 : 1)); // 날짜 순대로 정렬
    }
    catch (error) {
        console.error("Failed to fetch posts: ", error);
        return [];
    }
}
exports.getAllPosts = getAllPosts;
